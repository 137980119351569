import googleTagManager from "@analytics/google-tag-manager"
import Analytics from "analytics"

export type SelectedComponentsForAnalytics = {
  modelName: string
  modelId: number
  totalPrice: number
  detailingName: string
  colorName: string
  upholsteryName: string
  accessories: Array<{ name: string; id: string; price: number }>
}

const GTM_ID = process.env.GTM_ID
if (!GTM_ID) {
  throw new Error("Please provide the GTM_ID environment variable.")
}

/* Initialize analytics & load plugins */
const analytics = Analytics({
  app: "Mazda Ecommerce",
  plugins: [googleTagManager({ containerId: GTM_ID })],
})

export const trackPage = () => analytics.page()

export const trackImpressions = (models: Array<{ name: string; id: number }>) =>
  analytics.track("eec.impressions", {
    ecommerce: {
      currencyCode: "ILS",
      impressions: models.map(({ name, id }) => ({ name, id })),
    },
  })

export const trackProductClick = (name: string, id: number) =>
  analytics.track("productClick", {
    ecommerce: {
      click: {
        actionField: { list: "Homepage" },
        products: [{ name, id }],
      },
    },
  })

export const trackDetail = (name: string, id: number) =>
  analytics.track("eec.detail", {
    ecommerce: {
      detail: { products: [{ name, id }] },
    },
  })

const buildAnalyticsProducts = ({
  modelName,
  modelId,
  totalPrice,
  detailingName,
  colorName,
  upholsteryName,
  accessories,
}: SelectedComponentsForAnalytics) => [
  {
    name: modelName,
    id: modelId,
    price: totalPrice,
    variant: `${colorName} + ${upholsteryName}`,
    quantity: "1",
    dimension1: detailingName,
  },
  ...accessories,
]

export const trackAddToCart = (components: SelectedComponentsForAnalytics) =>
  analytics.track("addToCart", {
    ecommerce: {
      currencyCode: "ILS",
      add: {
        products: buildAnalyticsProducts(components),
      },
    },
  })

export const trackCheckoutStepWithComponents = (
  components: SelectedComponentsForAnalytics
) =>
  analytics.track("checkoutStep", {
    ecommerce: {
      checkout: {
        actionField: { step: "1" },
        products: buildAnalyticsProducts(components),
      },
    },
  })

export const trackCheckoutStep = (step: "2" | "3" | "4" | "5") =>
  analytics.track("checkoutStep", {
    ecommerce: {
      checkout: {
        actionField: { step },
      },
    },
  })

export const trackTransaction = (
  components: SelectedComponentsForAnalytics,
  orderId: number
) =>
  analytics.track("transaction", {
    ecommerce: {
      purchase: {
        actionField: {
          id: orderId,
          revenue: components.totalPrice,
        },
        products: buildAnalyticsProducts(components),
      },
    },
  })

export const trackLoggedIn = () => analytics.track("logged-in")

export const trackCallMeBack = () => analytics.track("callmeBack")
